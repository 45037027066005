import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Search, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import { Header } from '../components';
const Employees = () => {
  const [employeesData, setEmployeesData] = useState([]);

  useEffect(() => {
    // Fetch data from the backend
    axios.get('https://api.adlift.ai/llm/api/employee/')
      .then((response) => {
        setEmployeesData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const employeesGrid = [
    { field: 'employeeid', headerText: 'Employee ID', width: 100, textAlign: 'Center', isPrimaryKey: true },
    { field: 'name', headerText: 'Name', width: 150, textAlign: 'Center', allowFiltering: true },
    { field: 'title', headerText: 'Title', width: 150, textAlign: 'Center' },
    { field: 'hiredate', headerText: 'Hire Date', width: 150, textAlign: 'Center', format: 'yMd' },
    { field: 'country', headerText: 'Country', width: 150, textAlign: 'Center', allowFiltering: true },
    { field: 'reportsto', headerText: 'Reports To', width: 150, textAlign: 'Center' },
    { field: 'employeeimage', headerText: 'Employee Image', width: 150, textAlign: 'Center', template: (data) => (
      <img src={data.employeeimage} alt="Employee" className="employee-image" />
    )},
  ];

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header category="Page" title="Employees" />
      <GridComponent dataSource={employeesData} allowPaging allowSorting
        toolbar={['Search']} width="auto">
        <ColumnsDirective>
          {employeesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Search, Toolbar]} />
      </GridComponent>
    </div>
  );
};

export default Employees;
