import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import { Header } from '../components';
import './Customers.css';  // Import CSS for custom styling

const Customers = () => {
  const [customersData, setCustomersData] = useState([]);

  useEffect(() => {
    // Fetch data from the backend
    axios.get('https://api.adlift.ai/llm/api/customers/')
      .then((response) => {
        setCustomersData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const statusTemplate = (data) => {
    return (
      <span className={`status-bar ${data.status ? 'status-active' : 'status-inactive'}`}>
        {data.status ? 'Active' : 'Inactive'}
      </span>
    );
  };

  const onboardStatusTemplate = (data) => {
    let statusText = '';
    let statusClass = '';
    switch (data.onboard_status) {
      case 0:
        statusText = 'Inprogress';
        statusClass = 'status-inprogress';
        break;
      case 1:
        statusText = 'Hold';
        statusClass = 'status-hold';
        break;
      case 2:
        statusText = 'Onboarded';
        statusClass = 'status-onboarded';
        break;
      case 3:
        statusText = 'Cancel';
        statusClass = 'status-cancel';
        break;
      default:
        statusText = 'Unknown';
        statusClass = 'status-unknown';
        break;
    }
    return (
      <span className={`status-bar ${statusClass}`}>
        {statusText}
      </span>
    );
  };

  const customersGrid = [
    { field: 'id', headerText: 'ID', width: 90, textAlign: 'Center', isPrimaryKey: true },
    { field: 'name', headerText: 'Name', width: 150, textAlign: 'Center', allowFiltering: true },
    { field: 'email', headerText: 'Email', width: 200, textAlign: 'Center', allowFiltering: true },
    { field: 'phone', headerText: 'Phone No', width: 150, textAlign: 'Center' },
    { field: 'company_name', headerText: 'Company Name', width: 200, textAlign: 'Center' },
    { field: 'description', headerText: 'Description', width: 250, textAlign: 'Center' },
    { field: 'status', headerText: 'Status', width: 120, textAlign: 'Center', template: statusTemplate, allowFiltering: true },
    { field: 'country', headerText: 'Country', width: 150, textAlign: 'Center', allowFiltering: true },
    { field: 'onboard_status', headerText: 'Onboard Status', width: 150, textAlign: 'Center', template: onboardStatusTemplate, allowFiltering: true },
  ];

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header category="Page" title="Customers" />
      <GridComponent
        dataSource={customersData}
        allowPaging={true}
        allowSorting={true}
        allowFiltering={true}  // Enable Filtering
        toolbar={['Delete', 'Search']}  // Added Search option
        editSettings={{ allowDeleting: true, allowEditing: true }}
        width="auto"
      >
        <ColumnsDirective>
          {customersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Toolbar, Selection, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Customers;
